import React, { FC, ReactNode, useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import { Col, Row } from 'antd'
// import Marquee from 'react-fast-marquee'
// import NumberWithCommas from '~utils/NumberWithCommas'
// import { ApplicationState } from '~store/index'
import ProfileButton from '~components/Elements/ProfileButton'
import Emergency from '~components/Elements/Emergency'
import RestartWorker from '~components/Elements/RestartWorker'
import RestartAPI from './RestartAPI'
// import DateTimeNow from '~components/Elements/DateTimeNow'

interface TitleBarProps {
    title: string | ReactNode
    subTitle?: string | ReactNode
}

const TitleBar: FC<TitleBarProps> = ({ title, subTitle }) => {
    const [scrollPosition, setScrollPosition] = useState(0)
    // const authState = useSelector((state: ApplicationState) => state.auth)
    // const balance = authState.data.user?.balance
    // const currency = authState.data.user?.currency ? (authState.data.user?.currency).toUpperCase() : "THB"
    const onScroll = (offset: number) => setScrollPosition(offset)
    const funcSetScroll = () => window.addEventListener('scroll', () => onScroll(window.pageYOffset))


    useEffect(() => {
        funcSetScroll()
        return () => {
            onScroll(0);
        };
    }, []) //eslint-disable-line

    return (
        <>
            <div className={'title-bar ' + (scrollPosition > 50 ? 'scrolling' : '')}>
                <h2 className="mb-0 position-relative d-none d-lg-block">
                    {title}
                    {subTitle && (<small className="sub-title">{subTitle}</small>)}
                </h2>
                <div className="d-none d-lg-flex align-items-center ms-auto">
                    <RestartAPI />
                    <div className="line-separator"></div>
                    <RestartWorker />
                    <div className="line-separator"></div>
                    <Emergency username={""} />
                    <div className="line-separator"></div>
                    {/* <DateTimeNow />
                    <div className="line-separator"></div>
                    <div className="balance-card d-none d-lg-block">
                        <div className="balance-card-title">Balance</div>
                        <div className="balance-card-value">{NumberWithCommas(balance, 2)} {currency}</div>
                    </div>
                    <div className="line-separator"></div> */}
                    <ProfileButton className="d-none d-lg-block" username={"MSN"} role={"MASTER"} />
                </div>
            </div>
            <Row gutter={[0, 16]} style={{ marginBottom: 16 }}>
                <Col span={24} xs={{ order: 2 }} lg={{ order: 1 }}>
                    <h2 className="mb-0 position-relative d-lg-none">
                        {title}
                        {subTitle && (<small className="sub-title">{subTitle}</small>)}
                    </h2>
                </Col>
            </Row>
        </>
    )
}

export default TitleBar