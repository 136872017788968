import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Input, Select, Checkbox, Typography, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons';
import {
    Features
} from '~types/features'
import ServiceAPI from '~services/services'
import { AskmenetGroup } from './Game/AskmenetGroup'
import { JokerTechGroup } from './Game/JokerTechGroup'
import { BetflixGroup } from './Game/BetflixGroup'
import { SuperSlotGroup } from './Game/SuperSlotGroup'
import { UFABetGroup } from './Game/UFABetGroup'
import { PGSlotGroup } from './Game/PGSlotGroup'
import { AMBSuperAPIGroup } from './Game/AMBSuperAPIGroup'
import { GClub168Group } from './Game/GClub168Group'
import { UsunGroup } from './Game/UsunGroup'
import { SuperlotGroup } from './Game/SuperlotGroup'
import { Msnslot } from './Game/Msnslot'
import { AMBKing } from './Game/AMBKing';
import { PrefixFormValues } from '~types/prefixs'
import to from 'await-to-js'
import { toast } from 'react-toastify'

const { Title, Paragraph, Text, Link } = Typography;

interface ModalProps {
    close: () => void
    isOpen: boolean

    features: Features
    onloadData: () => void
}

const handleCopy = (data: any, domain: string) => {
    const values = `
รายละเอียดข้อมูล Prefix : ${data?.prefix}

เข้าสู่ระบบ สำหรับสมาชิก
https://${data.wallet}/login

สมัครสมาชิก สำหรับสมาชิก
https://${data.wallet}/register

สำหรับริชเมสเสจบน LINE@
https://${data.wallet}/login?openExternalBrowser=1

เข้าสู่ระบบหลังบ้าน สำหรับแอดมิน
https://${data.bo}/login

ชื่อผู้ใช้งาน: ${data?.username}
รหัสผ่าน: ${data?.password}
Prefix: ${data?.prefix}
Domain: ${domain || "-"}


--------------------------
หากมีข้อสงสัยหรือแจ้งปัญหาต่างๆในการใช้งาน โปรดติดต่อทีม Support ได้ที่ LINE: @autocsv999
ทางเราจะส่งข้อมูลสำคัญต่างๆที่กลุ่ม Support เท่านั้น! หากมีบุคคลแอบอ้างว่าเป็นทีมงานติดต่อไปยังไลน์แอดของท่าน ห้ามส่งให้เด็ดขาด (มิจฉาชีพ) ขอบคุณครับ
`
    navigator.clipboard.writeText(values)
    message.success('Copy successfully');
}
// คู่มือการใช้งาน: https://kb.msn.bet
const Add: FC<ModalProps> = ({ isOpen, close, features, onloadData }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const insertData = async (values: PrefixFormValues) => {
        const [err, res] = await to(ServiceAPI.addPrefixes(values))
        if (err) {
            toast.error(err.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            // Modal.success({
            //     content: res.message,
            // });
            Modal.success({
                content: (
                    <>
                        <Typography>
                            <Title level={5}>รายละเอียดข้อมูล Prefix : {res.prefix}</Title>
                            <Paragraph>
                                <Text strong>เข้าสู่ระบบ สำหรับสมาชิก</Text><br />
                                <Link href={`https://${res.wallet}/login`} target="_blank">{`https://${res.wallet}/login`}</Link><br /><br />

                                <Text strong>สมัครสมาชิก สำหรับสมาชิก</Text><br />
                                <Link href={`https://${res.wallet}/register`} target="_blank">{`https://${res.wallet}/register`}</Link><br /><br />

                                <Text strong>สำหรับริชเมสเสจบน LINE@</Text> <br />
                                <Link href={`https://${res.wallet}/login?openExternalBrowser=1`} target="_blank">{`https://${res.wallet}/login?openExternalBrowser=1`}</Link><br /><br />

                                <Text strong>เข้าสู่ระบบหลังบ้านสำหรับแอดมิน</Text> <br />
                                <Link href={`https://${res.bo}/login`} target="_blank">{`https://${res.bo}/login`}</Link><br /><br />

                                <Text strong>ชื่อผู้ใช้งาน:</Text> {res.username}<br />
                                <Text strong>รหัสผ่าน:</Text> {res.password}<br />
                                <Text strong>Prefix:</Text> {res.prefix}<br />
                                <Text strong>Domain:</Text> {values.domain}<br /><br />

                                {/* <Text strong>คู่มือการใช้งาน:</Text> <Link href="https://kb.msn.bet" target="_blank">https://kb.msn.bet</Link><br /><br /> */}

                                --------------------------<br />
                                หากมีข้อสงสัยหรือแจ้งปัญหาต่างๆในการใช้งาน โปรดติดต่อทีม Support ได้ที่ LINE: <Text strong>@autocsv999</Text><br />
                                <Text strong type="danger">ทางเราจะส่งข้อมูลสำคัญต่างๆที่กลุ่ม Support เท่านั้น! หากมีบุคคลแอบอ้างว่าเป็นทีมงานติดต่อไปยังไลน์แอดของท่าน ห้ามส่งให้เด็ดขาด (มิจฉาชีพ) ขอบคุณครับ</Text>
                            </Paragraph>
                        </Typography>
                        <Button
                            danger
                            block
                            onClick={() => handleCopy(res, values.domain)}
                            icon={<CopyOutlined />}>คัดลอก</Button>
                    </>),
                okText: 'ปิด',
            });
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: PrefixFormValues) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen) {
            form.resetFields();
        }
    }, [form, isOpen])

    const featuresCheckbox = features && Object.keys(features).map((item: any) => {
        return (
            <Form.Item
                name={['features', item]}
                key={item}
                valuePropName="checked"
                className="mb-0">
                <Checkbox>
                    {features[item]}
                </Checkbox>
            </Form.Item>
        )
    })
    const [gameSelect, setGameSelect] = useState<string>("")

    const handleGame = (e: string) => {
        setGameSelect(e);
    }

    return (
        <Modal
            title={`เพิ่ม Prefix`}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} loading={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Form
                form={form}
                name="prefixForm"
                layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="prefix"
                            label="Prefix"
                            rules={[{ required: true, message: 'กรุณากรอก Prefix!' }]}
                            hasFeedback>
                            <Input placeholder="Prefix" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="game"
                            label="Game"
                            rules={[{ required: true, message: 'กรุณาเลือกเกม!' }]}
                            hasFeedback>
                            <Select
                                placeholder="--- เลือกเกม ---"
                                showSearch
                                optionFilterProp="children"
                                onSelect={handleGame}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Select.Option value="msnslot" key="msnslot">MSNSLOT</Select.Option>
                                <Select.Option value="ambsuperapi" key="ambsuperapi">AMB Super API</Select.Option>
                                <Select.Option value="pgslot" key="pgslot">PGSlot</Select.Option>
                                <Select.Option value="joker123" key="joker123">Joker123</Select.Option>
                                <Select.Option value="slotxo" key="slotxo">SlotXO</Select.Option>
                                <Select.Option value="betflix" key="betflix">Betflix</Select.Option>
                                <Select.Option value="gclub168" key="gclub168">GClub168</Select.Option>
                                <Select.Option value="ambbet" key="ambbet">Ambbet</Select.Option>
                                <Select.Option value="ambking" key="ambking">AMB King</Select.Option>
                                <Select.Option value="wingbet" key="wingbet">Wingbet</Select.Option>
                                <Select.Option value="2goalbet" key="2goalbet">2Goalbet</Select.Option>
                                {/* <Select.Option value="kiss918" key="kiss918">Kiss918</Select.Option> */}
                                <Select.Option value="pussy888" key="pussy888">Pussy888</Select.Option>
                                <Select.Option value="ufa" key="ufa">UFABET</Select.Option>
                                <Select.Option value="superslot" key="superslot">SuperSlot</Select.Option>
                                <Select.Option value="usun" key="usun">Usun</Select.Option>
                                <Select.Option value="superlot" key="superlot">Superlot</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="agent"
                            label="Agent"
                            rules={[{ required: true, message: 'กรุณากรอก Agent!' }]}
                            hasFeedback>
                            <Input placeholder="Agent" inputMode="text" />
                        </Form.Item>
                    </Col>

                    {gameSelect === 'msnslot' && <Msnslot />}
                    {(gameSelect === 'ambbet' || gameSelect === 'wingbet' || gameSelect === '2goalbet') && (
                        <AskmenetGroup />
                    )}
                    {gameSelect === 'ambking' && <AMBKing />}
                    {(gameSelect === 'joker123' || gameSelect === 'slotxo') && (
                        <JokerTechGroup />
                    )}
                    {gameSelect === 'betflix' && <BetflixGroup />}
                    {gameSelect === 'superslot' && <SuperSlotGroup />}
                    {gameSelect === 'ufa' && <UFABetGroup />}
                    {gameSelect === 'pgslot' && <PGSlotGroup />}
                    {gameSelect === 'ambsuperapi' && <AMBSuperAPIGroup />}
                    {gameSelect === 'gclub168' && <GClub168Group />}
                    {gameSelect === 'usun' && <UsunGroup />}
                    {gameSelect === 'superlot' && <SuperlotGroup />}
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="domain"
                            label="Domain"
                            rules={[{ required: true, message: 'กรุณากรอก Domain!' }]}
                            extra={"ตัวอย่าง domain.com"}
                            hasFeedback>
                            <Input placeholder="domain.com" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="wallet_domain"
                            label="ทางเข้าเล่น"
                            rules={[{ required: true, message: 'กรุณากรอก Domain ทางเข้าเล่น!' }]}
                            extra={"ตัวอย่าง wallet.domain.com"}
                            hasFeedback>
                            <Input placeholder="wallet.domain.com" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="bo_domain"
                            label="หลังบ้าน"
                            rules={[{ required: true, message: 'กรุณากรอก Domain หลังบ้าน!' }]}
                            extra={"ตัวอย่าง bo.domain.com"}
                            hasFeedback>
                            <Input placeholder="bo.domain.com" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        {featuresCheckbox}
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Add