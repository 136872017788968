import { Tabs } from 'antd'
import React from 'react'
import TitleBar from '~components/Elements/TitleBar'
import Bo from './Bo'
import Web from './Web'

const Cloudflare = () => {
    return (
        <>
            <TitleBar title="Cloudflare" subTitle={``} />
            <Tabs size="large" type="card">
                <Tabs.TabPane tab="ทางเข้าเล่น" key="web-react">
                    <Web />
                </Tabs.TabPane>
                <Tabs.TabPane tab="หลังบ้าน" key="bo-react">
                    <Bo />
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}

export default Cloudflare
