import Button from 'antd-button-color'
import React, { useState } from 'react'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const RestartAPI = () => {
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)

    const fucRestartAPI = async () => {
        setSwitchLoading(true)
        Modal.confirm({
            title: `คุณต้องการ Restart API?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'ใช่',
            okType: 'danger',
            cancelText: 'ไม่',
            async onOk() {
                const [err, res] = await to(ServiceAPI.restartAPI())
                if (err) {
                    toast.error(err?.message as string)
                    setSwitchLoading(false)
                } else {
                    toast.success(res.message)
                    setSwitchLoading(false)
                }
            },
            onCancel() {
                setSwitchLoading(false)
            },
        });
    }
    return (
        <Button type="primary" className="text-dark" onClick={fucRestartAPI} loading={switchLoading}>
            <i className="fa-solid fa-repeat me-2"></i>
            Restart API
        </Button>
    )
}

export default RestartAPI
