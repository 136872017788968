import { Col, Form, Input } from 'antd';
import React, { FC } from 'react'
import { PrefixGameMetaTypes } from '~types/prefixs'

interface GameMetaGroupProps {
    gameMeta?: Record<PrefixGameMetaTypes, string>
}
export const BetflixGroup: FC<GameMetaGroupProps> = ({ gameMeta }) => {
    return (
        <>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "key"]}
                    label="API Key"
                    rules={[{ required: true, message: 'กรุณากรอก API Key!' }]}
                    hasFeedback>
                    <Input placeholder="API Key" inputMode="text" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "secret"]}
                    label="API Secret"
                    rules={[{ required: true, message: 'กรุณากรอก API Secret!' }]}
                    hasFeedback>
                    <Input placeholder="API Secret" inputMode="text" />
                </Form.Item>
            </Col>
        </>
    )
}
