import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Input, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import ServiceAPI from '~services/services'
import { RewardFormEditValues } from '~types/rewards'
import to from 'await-to-js'
import { toast } from 'react-toastify'

interface ModalProps {
    close: () => void
    isOpen: boolean
    data: any
    onloadData: () => void
}

const Edit: FC<ModalProps> = ({ isOpen, close, onloadData, data }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [loadingImage, setLoadingImage] = useState<boolean>(false)

    const [imageUrl, setImageUrl] = useState<string>('')
    const [imageUpload, setImageUpload] = useState<string>('')

    const updateData = async (values: RewardFormEditValues) => {
        const [err, res] = await to(ServiceAPI.editRewards(values))
        if (err) {
            toast.error(err.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: RewardFormEditValues | any) => {
                setBtnLoading(true)
                values.id = data.id
                values.file = imageUpload
                delete values.name
                updateData(values)

            })
            .catch((errorInfo) => {

            });
    };

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadLogoButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>อัพโหลดรูปภาพ</div>
        </div>
    );

    const uploadImage = async (response: any) => {
        setBtnLoading(true)
        setLoadingImage(true)
        setImageUpload(response.file)
        getBase64(response.file, (imageUrl: any) => {
            setImageUrl(imageUrl)
            setBtnLoading(false)
            setLoadingImage(false)
        });
    }

    useEffect(() => {
        if (isOpen && data) {
            form.resetFields();
            form.setFieldsValue(data);
            setImageUrl(data.image)
        }
    }, [form, isOpen, data])



    return (
        <Modal
            title={`แก้ไขของรางวัล`}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Form
                form={form}
                name="rewardForm"
                layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="name"
                            label="ชื่อ"
                            rules={[{ required: true, message: 'กรุณากรอกชื่อ!' }]}
                            hasFeedback>
                            <Input placeholder="ชื่อ" inputMode="text" readOnly />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Col span={12}>
                            <label style={{ marginBottom: '16px' }}>* รูปภาพ</label>
                            <Upload
                                name="image"
                                listType="picture-card"
                                showUploadList={false}
                                customRequest={(e) => uploadImage(e)}
                            >
                                {imageUrl ? <img src={imageUrl} alt="Reward" style={{ width: '100%' }} /> : uploadLogoButton}
                            </Upload>
                        </Col>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Edit