import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Select, Typography } from 'antd'
import ServiceAPI from '~services/services'
import { CommissionFormValues } from '~types/commission'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import Loading from '../Elements/Loading'

const { Title, Paragraph, Text } = Typography;
interface ModalProps {
    close: () => void
    isOpen: boolean

    onloadData: () => void
}

const Add: FC<ModalProps> = ({ isOpen, close, onloadData }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const [dataPrefixs, setDataPrefixs] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const getPrefixs = async () => {
        setIsLoading(true)
        setDataPrefixs([])
        const [err, res] = await to(ServiceAPI.getPrefixs())
        if (err) {
            toast.error(err?.message as string)
            setIsLoading(false)
        } else {
            // setDataPrefixs(Object.values(res).sort((a: any, b: any) => (a.active < b.active ? 1 : -1)))
            const prefixNotShow = ["UFALIVE"] // ไม่ต้องการแสดง
            const filteredData = Object.values(res)
                .filter((x: any) => x.active !== false && !prefixNotShow.includes(x.prefix))
                // .filter((x: any) => !prefixNotShow.includes(x.prefix))
                // .filter(obj => obj.features.commission === true)
                .sort((a: any, b: any) => (a.active < b.active ? 1 : -1));
            setDataPrefixs(filteredData);
            setIsLoading(false)
        }
    }

    const insertData = async (values: CommissionFormValues) => {
        const [err, res] = await to(ServiceAPI.addCommission(values))
        if (err) {
            toast.error(err.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            if (res.response) {
                Modal.success({
                    content: (
                        <>
                            <Typography>
                                <Title level={5}>Prefix : {res.response.prefix}</Title>
                                <Paragraph>
                                    <Text strong>API Key:</Text> {res.response.apiKey}<br />
                                </Paragraph>
                            </Typography>
                        </>),
                    okText: 'ปิด',
                });
            }
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: CommissionFormValues) => {
                setBtnLoading(true)
                insertData(values)

            })
            .catch((errorInfo) => {

            });
    };

    useEffect(() => {
        if (isOpen) {
            getPrefixs()
            form.resetFields();
        }
    }, [form, isOpen])

    return (
        <Modal
            title={`เพิ่ม Commission`}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} loading={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Form
                form={form}
                name="commissionForm"
                layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={24}>
                        {isLoading ? <Loading /> :
                            <Form.Item
                                name="prefix"
                                label="Prefix"
                                rules={[{ required: true, message: 'กรุณาเลือก Prefix!' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือก Prefix"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {dataPrefixs.map((dataPrefix: any) => (
                                        <Select.Option key={dataPrefix.prefix} value={dataPrefix.prefix}>{dataPrefix.prefix}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Add