import { Modal, PageHeader, Space, Tag, Typography } from 'antd'
import Button from 'antd-button-color'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// import Search from '~components/Elements/Search'
import Table from '~components/Elements/Table'
import useDatePagination from '~hooks/useDatePagination'
import useFetchCloudflare from '~hooks/useFetchCloudflare'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify'
import to from 'await-to-js'
import ServiceAPI from '~services/services'
import NumberWithCommas from '~utils/NumberWithCommas'
import AddCloudflare from '~components/Cloudflare/Add'

const Bo = () => {
    // const [filterText, setFilterText] = useState<string>('')
    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const toggleModalAdd = () => setIsAddOpen(!isAddOpen)

    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()

    const { data, mutate, isLoading } = useFetchCloudflare("bo-react", { page: pagination.page, limit: pagination.pageSize })


    const handleDelete = async (domain: string) => {
        Modal.confirm({
            title: `คุณต้องการลบ ${domain}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'เมื่อลบแล้วข้อมูลไม่สามารถกู้คืนได้',
            okText: 'ใช่',
            okType: 'danger',
            cancelText: 'ไม่',
            async onOk() {
                const [err, res] = await to(ServiceAPI.deleteCloudflare("bo-react", domain))
                if (err) {
                    toast.error(err.message as string)
                } else {
                    toast.success(res.success ? "Success" : "Error")
                    mutate()
                }
            },
            onCancel() {

            },
        });
    }

    const columns = [
        {
            title: 'Domain',
            dataIndex: 'name',
            render: (text: any, record: any) => (
                <>
                    <Typography.Link href={`https://${record.name}`} target="_blank">{record.name}</Typography.Link>
                </>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (text: any, record: any) => (
                <>
                    {record.status === "active" ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
                </>
            )
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'created_on',
            ellipsis: true,
            width: '140',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.created_on).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.created_on).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            align: 'center',
            render: (text: any, record: any) => (
                <Space size={5}>
                    <Button
                        type="danger"
                        size="small"
                        icon={<i className="fa-solid fa-trash me-2"></i>}
                        onClick={() => { handleDelete(record.name) }}>
                        ลบ
                    </Button>
                </Space >
            )
        }
    ]

    useEffect(() => {
        setDataSource([])
        if (data?.result !== undefined && data?.result_info.total_count > 0) {
            setDataSource(data?.result)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    return (
        <>
            <div className="d-flex justify-content-start mb-4">
                <Space>
                    <PageHeader
                        className="site-page-header"
                        title="หลังบ้าน"
                        subTitle={`${data ? NumberWithCommas(data?.result_info.total_count || 0) : 0} Domain`}
                    />
                    {/* <Search onChangeSearch={setFilterText} filterText={filterText} /> */}
                </Space>
            </div>
            <div className="row mb-md-3 align-items-md-center">
                <div className="mb-3 mb-md-0">
                    <Button
                        type="primary"
                        className="d-block ms-auto"
                        icon={<i className="fa-solid fa-plus me-3"></i>}
                        onClick={toggleModalAdd}>
                        เพิ่มหลังบ้าน
                    </Button>
                </div>
            </div>
            <div className="box-white ant-box-table">
                <Table
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.result_info.total_count || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
            {isAddOpen && (
                <AddCloudflare
                    isOpen={isAddOpen}
                    close={() => toggleModalAdd()}
                    onloadData={mutate}
                    projectName="bo-react"
                />
            )}
        </>
    )
}

export default Bo
