import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Input } from 'antd'
import ServiceAPI from '~services/services'
import { ICloudflareAdd } from '~types/cloudflare'
import to from 'await-to-js'
import { toast } from 'react-toastify'

interface ModalProps {
    close: () => void
    isOpen: boolean

    projectName: string
    onloadData: () => void
}

const Add: FC<ModalProps> = ({ isOpen, close, projectName, onloadData }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const title = projectName === "web-react" ? "เพิ่มทางเข้าเล่น" : "เพิ่มหลังบ้าน"

    const insertData = async (values: ICloudflareAdd) => {
        const [err, res] = await to(ServiceAPI.addCloudflare(projectName, values))
        if (err) {
            toast.error(err.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.success ? `Success : ${res.result.name}` : "Error")
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: ICloudflareAdd) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen) {
            form.resetFields();
        }
    }, [form, isOpen])


    return (
        <Modal
            title={title}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Form
                form={form}
                name="cfForm"
                layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={24}>
                        {projectName === "web-react" ? (
                            <Form.Item
                                name="domain"
                                label="ทางเข้าเล่น"
                                rules={[{ required: true, message: 'กรุณากรอก Domain ทางเข้าเล่น!' }]}
                                extra={"ตัวอย่าง wallet.domain.com"}
                                hasFeedback>
                                <Input placeholder="wallet.domain.com" inputMode="text" />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name="domain"
                                label="หลังบ้าน"
                                rules={[{ required: true, message: 'กรุณากรอก Domain หลังบ้าน!' }]}
                                extra={"ตัวอย่าง bo.domain.com"}
                                hasFeedback>
                                <Input placeholder="bo.domain.com" inputMode="text" />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Add