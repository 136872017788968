import { Col, Form, Input } from 'antd';
import React, { FC } from 'react'
import { PrefixGameMetaTypes } from '~types/prefixs'

interface GameMetaGroupProps {
    gameMeta?: Record<PrefixGameMetaTypes, string>
}
export const AskmenetGroup: FC<GameMetaGroupProps> = ({ gameMeta }) => {
    return (
        <>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "key"]}
                    label="Askmebet Key"
                    rules={[{ required: true, message: 'กรุณากรอก Askmebet Key!' }]}
                    hasFeedback>
                    <Input placeholder="Askmebet Key" inputMode="text" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "hash"]}
                    label="Askmebet Hash"
                    rules={[{ required: true, message: 'กรุณากรอก Askmebet Hash!' }]}
                    hasFeedback>
                    <Input placeholder="Askmebet Hash" inputMode="text" />
                </Form.Item>
            </Col>
        </>
    )
}
