import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Input, Select, Checkbox } from 'antd'
import {
    Features
} from '~types/features'
import ServiceAPI from '~services/services'
import { AskmenetGroup } from './Game/AskmenetGroup'
import { JokerTechGroup } from './Game/JokerTechGroup'
import { BetflixGroup } from './Game/BetflixGroup'
import { SuperSlotGroup } from './Game/SuperSlotGroup'
import { UFABetGroup } from './Game/UFABetGroup'
import { PGSlotGroup } from './Game/PGSlotGroup'
import { AMBSuperAPIGroup } from './Game/AMBSuperAPIGroup'
import { GClub168Group } from './Game/GClub168Group'
import { UsunGroup } from './Game/UsunGroup'
import { SuperlotGroup } from './Game/SuperlotGroup'
import { Msnslot } from './Game/Msnslot'
import { AMBKing } from './Game/AMBKing'
import { Prefixs, PrefixFormValues } from '~types/prefixs'
import to from 'await-to-js'
import { toast } from 'react-toastify'

interface ModalProps {
    close: () => void
    isOpen: boolean
    prefix: Prefixs
    features: Features
    onloadData: () => void
}

const Edit: FC<ModalProps> = ({ isOpen, close, prefix, features, onloadData }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [gameSelect, setGameSelect] = useState<string>("")



    const updateData = async (values: PrefixFormValues) => {
        const [err, res] = await to(ServiceAPI.editPrefixes(values))
        if (err) {
            toast.error(err.message as string)
            setBtnLoading(false)
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: PrefixFormValues) => {
                setBtnLoading(true)
                updateData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen && prefix) {
            form.resetFields();
            form.setFieldsValue(prefix);
            form.setFieldsValue({
                "game": prefix.config.game,
                "agent": prefix.config.agent
            });
            setGameSelect(prefix.config.game)
        }
    }, [form, isOpen, prefix])

    useEffect(() => {
        if (gameSelect && prefix) {
            form.setFieldsValue({
                "gameMeta": prefix.config.gameMeta,
            });
        }
    }, [gameSelect, prefix]) // eslint-disable-line

    const featuresCheckbox = features && Object.keys(features).map((item: any) => {
        return (
            <Form.Item
                name={['features', item]}
                key={item}
                valuePropName="checked"
                className="mb-0">
                <Checkbox>
                    {features[item]}
                </Checkbox>
            </Form.Item>
        )
    })

    const handleGame = (e: string) => {
        setGameSelect(e);
    }

    return (
        <Modal
            title={`แก้ไข Prefix`}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} loading={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Form
                form={form}
                name="prefixForm"
                layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="prefix"
                            label="Prefix"
                            rules={[{ required: true, message: 'กรุณากรอก Prefix!' }]}
                            hasFeedback>
                            <Input placeholder="Prefix" inputMode="text" readOnly />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="game"
                            label="Game"
                            rules={[{ required: true, message: 'กรุณาเลือกเกม!' }]}
                            hasFeedback>
                            <Select
                                placeholder="--- เลือกเกม ---"
                                showSearch
                                optionFilterProp="children"
                                onSelect={handleGame}
                                filterOption={(input: any, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Select.Option value="msnslot" key="msnslot">MSNSLOT</Select.Option>
                                <Select.Option value="ambsuperapi" key="ambsuperapi">AMB Super API</Select.Option>
                                <Select.Option value="pgslot" key="pgslot">PGSlot</Select.Option>
                                <Select.Option value="joker123" key="joker123">Joker123</Select.Option>
                                <Select.Option value="slotxo" key="slotxo">SlotXO</Select.Option>
                                <Select.Option value="betflix" key="betflix">Betflix</Select.Option>
                                <Select.Option value="gclub168" key="gclub168">GClub168</Select.Option>
                                <Select.Option value="ambbet" key="ambbet">Ambbet</Select.Option>
                                <Select.Option value="ambking" key="ambking">AMB King</Select.Option>
                                <Select.Option value="wingbet" key="wingbet">Wingbet</Select.Option>
                                <Select.Option value="2goalbet" key="2goalbet">2Goalbet</Select.Option>
                                {/* <Select.Option value="kiss918" key="kiss918">Kiss918</Select.Option> */}
                                <Select.Option value="pussy888" key="pussy888">Pussy888</Select.Option>
                                <Select.Option value="ufa" key="ufa">UFABET</Select.Option>
                                <Select.Option value="superslot" key="superslot">SuperSlot</Select.Option>
                                <Select.Option value="usun" key="usun">Usun</Select.Option>
                                <Select.Option value="superlot" key="superlot">Superlot</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="agent"
                            label="Agent"
                            rules={[{ required: true, message: 'กรุณากรอก Agent!' }]}
                            hasFeedback>
                            <Input placeholder="Agent" inputMode="text" />
                        </Form.Item>
                    </Col>
                    {gameSelect === 'msnslot' && <Msnslot />}
                    {(gameSelect === 'ambbet' || gameSelect === 'wingbet' || gameSelect === '2goalbet') && (
                        <AskmenetGroup />
                    )}
                    {gameSelect === 'ambking' && <AMBKing />}
                    {(gameSelect === 'joker123' || gameSelect === 'slotxo') && (
                        <JokerTechGroup />
                    )}
                    {gameSelect === 'betflix' && <BetflixGroup />}
                    {gameSelect === 'superslot' && <SuperSlotGroup />}
                    {gameSelect === 'ufa' && <UFABetGroup />}
                    {gameSelect === 'pgslot' && <PGSlotGroup />}
                    {gameSelect === 'ambsuperapi' && <AMBSuperAPIGroup />}
                    {gameSelect === 'gclub168' && <GClub168Group />}
                    {gameSelect === 'usun' && <UsunGroup />}
                    {gameSelect === 'superlot' && <SuperlotGroup />}
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="domain"
                            label="Domain"
                            rules={[{ required: true, message: 'กรุณากรอก Domain!' }]}
                            extra={"ตัวอย่าง domain.com"}
                            hasFeedback>
                            <Input placeholder="domain.com" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="wallet_domain"
                            label="ทางเข้าเล่น"
                            rules={[{ required: true, message: 'กรุณากรอก Domain ทางเข้าเล่น!' }]}
                            extra={"ตัวอย่าง wallet.domain.com"}
                            hasFeedback>
                            <Input placeholder="wallet.domain.com" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="bo_domain"
                            label="หลังบ้าน"
                            rules={[{ required: true, message: 'กรุณากรอก Domain หลังบ้าน!' }]}
                            extra={"ตัวอย่าง bo.domain.com"}
                            hasFeedback>
                            <Input placeholder="bo.domain.com" inputMode="text" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        {featuresCheckbox}
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Edit