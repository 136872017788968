import { Col, Form, Input } from 'antd';
import React, { FC } from 'react'
import { PrefixGameMetaTypes } from '~types/prefixs'

interface GameMetaGroupProps {
    gameMeta?: Record<PrefixGameMetaTypes, string>
}
export const SuperSlotGroup: FC<GameMetaGroupProps> = ({ gameMeta }) => {
    return (
        <>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "password"]}
                    label="Password"
                    rules={[{ required: true, message: 'กรุณากรอก Password!' }]}
                    hasFeedback>
                    <Input placeholder="Password" inputMode="text" />
                </Form.Item>
            </Col>
        </>
    )
}
