import { ISidebar } from '~types/ISidebar'
// import i18n from "~utils/i18n"

const onLoadMenuSidebar = () => {
    const menuSidebar: Array<ISidebar> = [
        {
            id: 'dashboard',
            permission_key: '',
            title: "ภาพรวม",
            path: "/dashboard",
            icon: "fa-duotone fa-chart-pie",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'prefixs',
            permission_key: '',
            title: "เว็บไซต์ทั้งหมด",
            path: "/prefixs",
            icon: "fa-duotone fa-browser",
            isShow: true,
            parent_id: '0'
        },
        // {
        //     id: 'invoice',
        //     permission_key: '',
        //     title: "ใบแจ้งหนี้",
        //     path: "/invoice",
        //     icon: "fa-duotone fa-file-invoice-dollar",
        //     isShow: true,
        //     parent_id: '0'
        // },
        {
            id: 'rewards',
            permission_key: '',
            title: "ของรางวัล",
            path: "/rewards",
            icon: "fa-duotone fa-gift",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'commission',
            permission_key: '',
            title: "Commission",
            path: "/commission",
            icon: "fa-solid fa-people-group",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'cloudflare',
            permission_key: '',
            title: "Cloudflare",
            path: "/cloudflare",
            icon: "fa-brands fa-cloudflare",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'gclubreset',
            permission_key: '',
            title: "Gclub Reset Password",
            path: "/gclub-reset-password",
            icon: "fa-duotone fa-key",
            isShow: true,
            parent_id: '0'
        },
    ]

    const listMenuSidebar: any = menuSidebar?.reduce((item: any, menu: any) => {
        if (menu.parent_id === '0') {
            item[menu.id] = menu
        } else {
            if (typeof item[menu.parent_id]['submenu'] === 'undefined') {
                item[menu.parent_id]['submenu'] = {}
            }
            item[menu.parent_id]['submenu'][menu.id] = menu
        }
        return item;
    }, {})

    return listMenuSidebar
}


// // i18next seems ready -> initial fill translations
// // if (i18n.isInitialized) {
// //     onLoadMenuSidebar();
// // }

// //   // reset translations to new values on language change
// i18n.on('languageChanged', function (lng) {
//     onLoadMenuSidebar();
// });

export default onLoadMenuSidebar


