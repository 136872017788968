import React, { useEffect, useState } from 'react'
import TitleBar from '~components/Elements/TitleBar'
import { Form, Row, Col, Input, Divider, Space, Button, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { GclubresetFormValues } from '~types/gclubreset'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'


const GclubReset = () => {

    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const { confirm } = Modal;

    const insertData = async (values: GclubresetFormValues) => {
        const [err, res] = await to(ServiceAPI.gclubResetPassword(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
            form.resetFields();
        } else {
            toast.success(res.message)
            setBtnLoading(false)
            form.resetFields();
        }
    }

    const handleFormSubmit = () => {
        confirm({
            title: `ยืนยันการเปลี่ยน Password?`,
            icon: <ExclamationCircleOutlined />,
            content: ``,
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk() {
                (async () => {
                    form.validateFields()
                        .then((values: GclubresetFormValues) => {
                            setBtnLoading(true)
                            insertData(values)
                        })
                        .catch((errorInfo) => {

                        });
                })();
            },
            onCancel() {
                setBtnLoading(false)
            },
        });

    };

    useEffect(() => {
        if (form) {
            form.resetFields();
        }
    }, [form])

    return (
        <>
            <TitleBar title="Gclub reset password" />
            <div className="box-white">
                <Form
                    form={form}
                    name="gclubForm"
                    layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} sm={24}>
                            <Form.Item
                                name="agent"
                                label="Agent"
                                rules={[{ required: true, message: 'กรุณากรอก Agent!' }]}
                                hasFeedback>
                                <Input placeholder="Agent" inputMode="text" autoComplete='new-agent' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'กรุณากรอก Password!' }]}
                                hasFeedback>
                                <Input.Password placeholder="Password" inputMode="text" autoComplete='new-password' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </>
    )
}
export default GclubReset