export const exportMemberOptions: Record<string, string> = {
    phone: 'เบอร์โทรศัพท์',
    name: 'ชื่อสมาชิก',
    bankCode: 'ชื่อธนาคาร',
    bankNumber: 'เลขที่บัญชี',
    Deposit: 'มีเงินฝาก',
    noDeposit: 'ไม่มีเงินฝาก',
}

export interface ExportResponse {
    downloadURL: string
}

export interface ExportData {
    endPoint: string
    options: Record<string, boolean>
    minDeposit: number
    start: string
    end: string
}