import React, { FC, KeyboardEvent } from 'react'
import { Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons';

interface SearchProps {
    onChangeSearch: (e: any) => void
    onHandleSearch?: () => void
    filterText: string
}

const Search: FC<SearchProps> = ({ onChangeSearch, onHandleSearch, filterText }) => {

    const handleKeyboardEvent = (e: KeyboardEvent<HTMLImageElement>) => {
        if (e.key === 'Enter') {
            onHandleSearch && onHandleSearch()
        }
    };

    return (
        <div className="d-flex">
            <Space
                direction="vertical"
                size={12}>
                <Input
                    size="large"
                    addonBefore={<SearchOutlined style={{ marginBottom: '6px' }} />}
                    allowClear
                    placeholder="ค้นหา"
                    value={filterText ?? ''}
                    onChange={(e: any) => onChangeSearch(e.target.value)}
                    onKeyPress={(e: any) => handleKeyboardEvent(e)}
                />
            </Space>
        </div>

    )
}

export default Search