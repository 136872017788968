import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
// import { useDispatch } from 'react-redux'
import { useNavigate, Navigate } from 'react-router-dom'
// import { AnyAction } from 'redux'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'
// import { requestAuthInit } from '~store/auth/auth.actions'
import { LoginParam } from '~types/auth'
// import { ThunkDispatch } from '~types/store'
import AuthService from '~services/auth'
import PasswordInput from '~components/Elements/PasswordInput'
import { menuItems } from '~utils/RouteConfig'
// import { Button } from 'antd'
import logo from '../../assets/img/logo-light.png'
// Dropdown, Menu, 
const Login = () => {
    const navigate = useNavigate();
    // const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    // const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [tokenGoogle, setTokenGoogle] = useState<string>("")
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (tokenGoogle) {
            // toast.error("Execute recaptcha not yet available, Please reload page.")
            // return;
            localStorage.setItem("googleRecaptcha", tokenGoogle)
        }
        try {
            await AuthService.login({
                password: password,
            } as LoginParam)
            // dispatch(requestAuthInit(true))
            navigate('/dashboard')
        } catch (error: any) {
            toast.error(error ? error.message as string : "ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้")
        }
    }

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('Login');
        setTokenGoogle(token)
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const token = localStorage.getItem('x-auth-token')
    if (token) {
        return <Navigate to={menuItems[0]?.path} replace />
    }
    return (
        <Wrapper>
            <SignIn>
                <img className="mb-5 d-block mx-auto" src={logo} alt="logo msn-bet" width="180" />
                <form onSubmit={handleSubmit}>
                    <PasswordInput
                        password={password}
                        handleChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">เข้าสู่ระบบ</button>
                    <p className="mt-4 mb-0 text-center text-muted"><small>Copyright © 2022 <a href="/">MASTER MSN.bet</a>. All rights reserved.</small></p>
                </form>
            </SignIn>
        </Wrapper>
    )
}

const LoginWithReCaptcha = () => {
    const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY : "6LdaSW4iAAAAAAZwOnBqeHCv1jioDSV4jJiiUIx_"
    return (
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY} language="th">
            <Login />
        </GoogleReCaptchaProvider>
    );
};

export default LoginWithReCaptcha

// const Wrapper = styled.div`
// 	// height: 100vh;
// 	// display: flex;
// 	// align-items: center;
// 	// justify-content: center;
// 	// background: #F8F9FD;
// `;

// const SignIn = styled.main`
// 	// width: 100%;
// 	// max-width: 400px;
// 	// form {
// 	// 	padding: 24px;
// 	// 	background: #fff;
// 	// 	box-shadow: 0 12px 24px 0 rgba(128,144,208,0.08);
// 	// 	border-radius: 16px;
// 	// }
// `;

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F8F9FD;
`;

const SignIn = styled.main`
	width: 100%;
	max-width: 400px;

	form {
		padding: 24px;
		background: #fff;
		box-shadow: 0 12px 24px 0 rgba(128,144,208,0.08);
		border-radius: 16px;
	}
`;