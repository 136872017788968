import { CommonResponse, CommonResponseAddPrefix } from '~types/api'
import api from '~utils/api'
import { Prefixs, PrefixFormValues } from '~types/prefixs'
import { Features } from '~types/features'
import { ExportData, ExportResponse } from '~types/export'
import { Reward, RewardFormValues, RewardFormEditValues } from '~types/rewards'
import { GclubresetFormValues } from '~types/gclubreset'
import { PaginationParams } from '~types/pagination'
import { ICloudflare, ICloudflareAdd, ICloudflareResponse } from '~types/cloudflare'
import { Commission, CommissionFormValues, CommonResponseCommission } from '~types/commission'


class ServiceAPI {


	getPrefixs(): Promise<Prefixs> {
		return api.get<void, Prefixs>(`/prefixes`)
	}

	getFeatures(): Promise<Features> {
		return api.get<void, Features>(`/features`)
	}

	addPrefixes(data: PrefixFormValues): Promise<CommonResponseAddPrefix> {
		return api.post<void, CommonResponseAddPrefix>(`/prefixes/${data.prefix}`, data)
	}

	editPrefixes(data: PrefixFormValues): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/prefixes/${data.prefix}`, data)
	}

	deletePrefixes(prefix: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/prefixes/${prefix}`)
	}

	togglePrefixes(prefix: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/prefixes/${prefix}/toggle`)
	}

	toggleWalletPrefixes(prefix: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/prefixes/${prefix}/toggleWallet`)
	}

	updateOwnerPrefixes(prefix: string, owner: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/prefixes/${prefix}/owner`, {
			owner: owner,
		})
	}

	exportPrefixes(prefix: string, data: ExportData): Promise<ExportResponse> {
		return api.post<void, ExportResponse>(`/prefixes/${prefix}/export`, data)
	}

	getRewards(): Promise<Reward> {
		return api.get<void, any>(`/rewards`)
	}

	addRewards(data: RewardFormValues): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/rewards`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
	}

	editRewards(data: RewardFormEditValues): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/rewards`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
	}

	deleteRewards(id: number): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/rewards/${id}`)
	}


	gclubResetPassword(data: GclubresetFormValues): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/game/gclub/reset-password`, data)
	}

	getCloudflare(projectName: string = "", params?: PaginationParams): Promise<ICloudflare> {
		return api.get<void, ICloudflare>(`/cloudflare/domains/${projectName}`, {
			params: {
				per_page: params?.limit,
				page: params?.page,
			},
		})
	}

	addCloudflare(projectName: string, data: ICloudflareAdd): Promise<ICloudflareResponse> {
		return api.post<void, ICloudflareResponse>(`/cloudflare/domains/${projectName}`, data)
	}

	deleteCloudflare(projectName: string, domain: string): Promise<ICloudflareResponse> {
		return api.delete<void, ICloudflareResponse>(`/cloudflare/domains/${projectName}/${domain}`)
	}

	restartWorker(): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/k8s/restart-msn-worker`)
	}
	restartAPI(): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/k8s/restart-msn-api`)
	}

	getCommission(): Promise<Commission> {
		return api.get<void, any>(`/commission`)
	}

	addCommission(data: CommissionFormValues): Promise<CommonResponseCommission> {
		return api.post<void, CommonResponseCommission>(`/commission/${data.prefix}`)
	}

	refreshApiKeyCommission(prefix: string): Promise<CommonResponseCommission> {
		return api.patch<void, CommonResponseCommission>(`commission/${prefix}/refresh-apikey`)
	}


	//////////////////////////////////////////////////////////////////////////////////////////////

	// addMember(data: MemberAdd): Promise<CommonResponse> {
	// 	return api.put<void, CommonResponse>('/ag/members', data)
	// }

	// addAgent(data: AgentAdd): Promise<CommonResponse> {
	// 	return api.put<void, CommonResponse>('/ag/agents', data)
	// }

	// addSubUser(data: SubUserAdd): Promise<CommonResponse> {
	// 	return api.put<void, CommonResponse>('/ag/sub-users', data)
	// }

	// getUser(username: string): Promise<Users> {
	// 	return api.get<void, Users>(`/ag/users/${username}`)
	// }

	// getHistoryEditUser(username: string): Promise<UsersHistory> {
	// 	return api.get<void, UsersHistory>(`/ag/users/${username}/logs`)
	// }

	// getViewHistoryEditUser(username: string, id: string): Promise<ViewUsersHistoryData> {
	// 	return api.get<void, ViewUsersHistoryData>(`/ag/users/${username}/logs/${id}`)
	// }

	// updateUser(username: string, data: UserEdit): Promise<CommonResponse> {
	// 	return api.put<void, CommonResponse>(`/ag/users/${username}`, data)
	// }

	// updateSubUser(username: string, data: SubUserEdit): Promise<CommonResponse> {
	// 	return api.put<void, CommonResponse>(`/ag/sub-users/${username}`, data)
	// }

	// getUserChildren(username: string, role: UserRole, params: PaginationParams): Promise<Users> {
	// 	return api.get<void, Users>(`/ag/users/${username}/children`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 			role: role,
	// 		},
	// 	})
	// }

	// getSubUserList(params: PaginationParams): Promise<SubUsers> {
	// 	return api.get<void, SubUsers>(`/ag/sub-users`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 		},
	// 	})
	// }

	// getSubUser(username: string): Promise<SubUsers> {
	// 	return api.get<void, SubUsers>(`/ag/sub-users/${username}`)
	// }

	// setUsersRestrict(username: string, data: RestrictParams): Promise<CommonResponse> {
	// 	return api.patch<void, CommonResponse>(`/ag/users/${username}/restrict`, data)
	// }

	// setSubUsersRestrict(username: string, data: RestrictParams): Promise<CommonResponse> {
	// 	return api.patch<void, CommonResponse>(`/ag/sub-users/${username}/restrict`, data)
	// }

	// setUsersChangePassword(username: string, data: PasswordParams): Promise<CommonResponse> {
	// 	return api.patch<void, CommonResponse>(`/ag/users/${username}/password`, data)
	// }

	// setSubUsersChangePassword(username: string, data: PasswordParams): Promise<CommonResponse> {
	// 	return api.patch<void, CommonResponse>(`/ag/sub-users/${username}/password`, data)
	// }

	// setUsersChangeNewPassword(data: NewPasswordParams): Promise<CommonResponse> {
	// 	return api.patch<void, CommonResponse>(`/ag/me/change-password`, data)
	// }

	// setUsersDeposit(username: string, data: TransferAdd): Promise<CommonResponse> {
	// 	return api.post<void, CommonResponse>(`/ag/users/${username}/deposit`, data)
	// }

	// setUsersWithdraw(username: string, data: TransferAdd): Promise<CommonResponse> {
	// 	return api.post<void, CommonResponse>(`/ag/users/${username}/withdraw`, data)
	// }

	// getAccessLogs(params: PaginationParams): Promise<AccessLogs> {
	// 	return api.get<void, AccessLogs>(`/ag/logs/access-logs`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 		},
	// 	})
	// }

	// getPaymentLogs(params: PaginationParams): Promise<PaymentLogs> {
	// 	return api.get<void, PaymentLogs>(`/ag/logs/payment-logs`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 		},
	// 	})
	// }

	// getFeedbacks(params: FeedbackParams): Promise<Feedback> {
	// 	return api.get<void, Feedback>(`/ag/feedbacks`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 			parent: params?.parent,
	// 			type: params?.type,
	// 		},
	// 	})
	// }

	// getReportWinLoss(params: WinLossParams): Promise<WinLoss> {
	// 	return api.get<void, WinLoss>(`/ag/reports/daily-reports`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 			username: params?.username,
	// 			startDate: params?.startDate,
	// 			endDate: params?.endDate,
	// 		},
	// 	})
	// }
	// getReportWinLossMember(params: WinLossParams): Promise<WinLossMember> {
	// 	return api.get<void, WinLossMember>(`/ag/reports/transactions`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 			username: params?.username,
	// 			startDate: params?.startDate,
	// 			endDate: params?.endDate,
	// 		},
	// 	})
	// }

	// getPaymentStatement(username: string, params: PaginationParams): Promise<PaymentStatements> {
	// 	return api.get<void, PaymentStatements>(`/ag/payments/statements/${username}`, {
	// 		params: {
	// 			limit: params.limit,
	// 			page: params.page,
	// 		},
	// 	})
	// }
}

export default new ServiceAPI()
