import TitleBar from '~components/Elements/TitleBar'

const Dashboard = () => {
    return (
        <>
            <TitleBar title="ภาพรวม" subTitle={""} />
            ภาพรวม
        </>
    );
};

export default Dashboard