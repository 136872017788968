import React, { useEffect, useState } from 'react'
import { Modal, Space, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Button from 'antd-button-color'
// import to from 'await-to-js'
// import { toast } from 'react-toastify'
// import ServiceAPI from '~services/services'
// import Fuse from 'fuse.js'
import TitleBar from '~components/Elements/TitleBar'
import NumberWithCommas from '~utils/NumberWithCommas'
import Search from '~components/Elements/Search'
import TableAntd from '~components/Elements/TableAntd'
import AddCommission from '~components/Commission/Add'
import moment from 'moment'
import to from 'await-to-js'
import ServiceAPI from '~services/services'
import { toast } from 'react-toastify'
import Fuse from 'fuse.js';

const { Title, Paragraph, Text } = Typography;
const Commission = () => {

    const [dataSource, setDataSource] = useState<any>([])
    const [dataCommission, setDataCommission] = useState<any>([])

    const [filterText, setFilterText] = useState('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const toggleModalAdd = () => setIsAddOpen(!isAddOpen)

    const getCommission = async () => {
        setIsLoading(true)
        setDataCommission([])
        const [err, res] = await to(ServiceAPI.getCommission())
        if (err) {
            toast.error(err?.message as string)
            setIsLoading(false)
        } else {
            setDataCommission(res.data)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCommission()
        return () => {
            setDataSource([])
            setDataCommission([])
        }
    }, [])


    const toggleRefreshAPIKey = async (prefix: string) => {
        Modal.confirm({
            title: `คุณต้องการ Refresh API Key Prefix : ${prefix}?`,
            icon: <ExclamationCircleOutlined />,
            content: `ตรวจสอบให้แน่ใจว่าคุณต้องการ Refresh API Key?`,
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk() {
                (async () => {
                    const [err, res] = await to(ServiceAPI.refreshApiKeyCommission(prefix))
                    if (err) {
                        toast.error(err?.message as string)
                        setBtnLoading(false)
                    } else {
                        getCommission()
                        toast.success(res.message)
                        if (res.response) {
                            Modal.success({
                                content: (
                                    <>
                                        <Typography>
                                            <Title level={5}>Prefix : {res.response.prefix}</Title>
                                            <Paragraph>
                                                <Text strong>API Key:</Text> {res.response.apiKey}<br />
                                            </Paragraph>
                                        </Typography>
                                    </>),
                                okText: 'ปิด',
                            });
                        }
                        setBtnLoading(false)
                    }
                })();
            },
            onCancel() {
            },
        });
    };

    const handleSearch = () => {
        if (Object.keys(dataCommission).length > 0) {
            const fuseSearch = new Fuse<any>(dataCommission, {
                useExtendedSearch: true,
                keys: ['prefix'],
            })
            if (filterText !== '') {
                setDataSource(fuseSearch.search(`^${filterText}`).map((x) => x.item))
                // setDataSource(fuseSearch.search(filterText).map((x) => x.item))
            } else {
                setDataSource(dataCommission)
            }
        } else {
            setDataSource(dataCommission)
        }
    }

    const columns = [
        {
            title: 'Prefix',
            dataIndex: 'prefix',
            render: (text: any, record: any) => (
                <Typography.Text strong>{record.prefix}</Typography.Text>
            )
        },
        {
            title: 'Start TxID',
            dataIndex: 'startTxId',
            align: 'center',
            width: '200',
            render: (text: any, record: any) => (
                <Typography.Text>{record.startTxId}</Typography.Text>
            )
        },
        {
            title: 'Date Start TxID',
            dataIndex: 'dateStartTxId',
            width: '120',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    {/* {record.dateStartTxId ? (
                        <>
                            <div><span>{moment(record.dateStartTxId).format('DD/MM/YYYY')}</span></div>
                            <div>{moment(record.dateStartTxId).format('HH:mm:ss')}</div>
                        </>
                    ) :
                } */}
                    <span>{record.dateStartTxId}</span>
                </div>
            )
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            ellipsis: true,
            width: '120',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'updatedAt',
            ellipsis: true,
            width: '120',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.updatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.updatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            align: 'center',
            render: (text: any, record: any) => (
                <Space size={1}>
                    <Button
                        type="danger"
                        size="small"
                        loading={btnLoading}
                        icon={<i className="fa-solid fa-arrows-rotate me-2"></i>}
                        onClick={() => {
                            // setDataAgent(record)
                            setBtnLoading(true)
                            toggleRefreshAPIKey(record.prefix);
                        }}>
                        Refresh API Key
                    </Button>
                </Space >
            )
        }
    ]


    useEffect(() => {
        handleSearch()
    }, [filterText, dataCommission]) // eslint-disable-line


    return (
        <>
            <TitleBar title="Commission" subTitle={`${NumberWithCommas(dataSource?.length || 0)} รายการ`} />
            <div className="d-flex justify-content-start mb-4">
                <Search onChangeSearch={setFilterText} filterText={filterText} />
            </div>
            <div className="row mb-md-3 align-items-md-center">
                <div className="mb-3 mb-md-0">
                    <Button
                        type="primary"
                        className="d-block ms-auto"
                        icon={<i className="fa-solid fa-plus me-3"></i>}
                        onClick={toggleModalAdd}>
                        เพิ่ม Commission
                    </Button>
                </div>
            </div>
            <div className="box-white ant-box-table">
                <TableAntd
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                />
            </div>

            {isAddOpen &&
                <AddCommission
                    isOpen={isAddOpen}
                    close={() => toggleModalAdd()}
                    onloadData={getCommission}
                />
            }
            {/* {isEditOpen &&
                <EditReward
                    isOpen={isEditOpen}
                    close={() => toggleModalEdit()}
                    onloadData={getRewards}
                    data={rewardUpdate}
                />
            } */}

        </>
    )
}

export default Commission