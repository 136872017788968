import React, { useRef, useEffect } from 'react'
import jsPDF from 'jspdf'
import Button from 'antd-button-color'
import logo from '~assets/img/logo-dark.png'

const Invoice = () => {
    var canvas, ctx: any
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const sources: any = {
        logo: logo
    }

    const mockupData = [
        {
            id: 1,
            prefix: 'BF',
            active: true,
            promotion: '-',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 2,
            prefix: 'PGTT',
            active: true,
            promotion: 'C',
            serviceCharge: 30000,
            features: {
                cashback: 0,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 0,
            total: 58443,
        }, {
            id: 3,
            prefix: 'UFA',
            active: true,
            promotion: 'A',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 4,
            prefix: 'AMBBEEE',
            active: true,
            promotion: '-',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 5,
            prefix: 'PGTT',
            active: true,
            promotion: 'C',
            serviceCharge: 30000,
            features: {
                cashback: 0,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 0,
            total: 58443,
        }, {
            id: 6,
            prefix: 'UFA',
            active: true,
            promotion: 'A',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 7,
            prefix: 'PGCZ',
            active: false,
            promotion: '-',
            serviceCharge: 0,
            features: {
                cashback: 0,
                scbApi: 0,
                truewallet: 0,
                luckywheel: 0,
                apiAmbbet: 0,
            },
            discount: 0,
            total: 0,
        }, {
            id: 1,
            prefix: 'BF',
            active: true,
            promotion: '-',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 2,
            prefix: 'PGTT',
            active: true,
            promotion: 'C',
            serviceCharge: 30000,
            features: {
                cashback: 0,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 0,
            total: 58443,
        }, {
            id: 3,
            prefix: 'UFA',
            active: true,
            promotion: 'A',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 4,
            prefix: 'AMBBEEE',
            active: true,
            promotion: '-',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 5,
            prefix: 'PGTT',
            active: true,
            promotion: 'C',
            serviceCharge: 30000,
            features: {
                cashback: 0,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 0,
            total: 58443,
        }, {
            id: 6,
            prefix: 'UFA',
            active: true,
            promotion: 'A',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 7,
            prefix: 'PGCZ',
            active: false,
            promotion: '-',
            serviceCharge: 0,
            features: {
                cashback: 0,
                scbApi: 0,
                truewallet: 0,
                luckywheel: 0,
                apiAmbbet: 0,
            },
            discount: 0,
            total: 0,
        }, {
            id: 1,
            prefix: 'BF',
            active: true,
            promotion: '-',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 2,
            prefix: 'PGTT',
            active: true,
            promotion: 'C',
            serviceCharge: 30000,
            features: {
                cashback: 0,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 0,
            total: 58443,
        }, {
            id: 3,
            prefix: 'UFA',
            active: true,
            promotion: 'A',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 4,
            prefix: 'AMBBEEE',
            active: true,
            promotion: '-',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 5,
            prefix: 'PGTT',
            active: true,
            promotion: 'C',
            serviceCharge: 30000,
            features: {
                cashback: 0,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 0,
            total: 58443,
        }, {
            id: 6,
            prefix: 'UFA',
            active: true,
            promotion: 'A',
            serviceCharge: 30000,
            features: {
                cashback: 15000,
                scbApi: 5000,
                truewallet: 5000,
                luckywheel: 0,
                apiAmbbet: 5000,
            },
            discount: 11000,
            total: 44000,
        }, {
            id: 7,
            prefix: 'PGCZ',
            active: false,
            promotion: '-',
            serviceCharge: 0,
            features: {
                cashback: 0,
                scbApi: 0,
                truewallet: 0,
                luckywheel: 0,
                apiAmbbet: 0,
            },
            discount: 0,
            total: 0,
        },
    ]

    const mockupPromotions = [
        {
            id: 1,
            code: 'A',
            title: 'ลดค่าบริการรายเดือน 50%'
        }, {
            id: 2,
            code: 'B',
            title: 'ฟรีฟังก์ชั่น (ยอด Win/Loss เกิน 2 ล้าน)'
        }, {
            id: 3,
            code: 'C',
            title: 'ฟรีฟังก์ชั่น 1 เดือนแรก'
        },
    ]

    const numberWithCommas = (num: any) => {
        num = num.toString();
        const pattern = /(-?\d+)(\d{3})/
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2")
        return num;
    }

    const colDataTable: any = [90, 245, 355, 465, 575, 685, 795, 905, 1015, 1150]
    let currentRowPoint: number = 490
    let numPrefix: number = mockupData.length /** !! อย่าลืมเอาจำนวนของ prefix ต้องนำมาคำนวณ */

    const init = () => {
        if (canvasRef.current) {
            canvas = canvasRef.current
            canvas.width = 1240
            canvas.height = numPrefix <= 33 ? 1754 : (1754 + ((numPrefix - 33) * 25))
            ctx = canvas.getContext('2d')
            draw()
        }
    }

    const loadImages = async (source: any, callback: (e: any) => void) => {
        var images: any = {};
        var loadedImages = 0;
        var numImages = Object.values(source).length;

        Object.keys(source).forEach((src: any) => {
            images[src] = new Image();
            images[src].crossOrigin = 'anonymous'
            images[src].onload = function () {
                loadedImages++;
                if (loadedImages >= numImages) {
                    callback(images);
                }
            }
            images[src].src = sources[src];
        })
    }

    const headTable = () => {
        // Background
        ctx.fillStyle = '#ffc602'
        ctx.fillRect(50, 370, 1140, 80)

        // Prefix
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'start';
        ctx.fillText('Prefix', colDataTable[0], 415)

        // Promotion
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('Promotion', colDataTable[1], 415)

        // Service charge
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('ค่าบริการ', colDataTable[2], 415)

        // Feature
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('ฟีเจอร์', colDataTable[5], 395)

        // Line
        ctx.beginPath();
        ctx.moveTo(415, 410);
        ctx.lineTo(960, 410);
        ctx.stroke();

        // Cashback
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('แคชแบ็ก', colDataTable[3], 435)

        // SCB API
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('SCB API', colDataTable[4], 435)

        // Truewallet
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('True Wallet', colDataTable[5], 435)

        // Lucky wheel
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('กงล้อเสี่ยงโชค', colDataTable[6], 435)

        // API Ambbet
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('API Ambbet', colDataTable[7], 435)

        // Discount
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center';
        ctx.fillText('ส่วนลด 20%', colDataTable[8], 415)

        // Total
        ctx.font = 'bold 16px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'right';
        ctx.fillText('ราคา', colDataTable[9], 415)
    }

    const dataTable = () => {
        headTable()
        mockupData.forEach((data: any) => {
            // Prefix
            ctx.font = '18px Arial'
            ctx.fillStyle = data?.active ? '#000' : '#f00'
            ctx.textAlign = 'start';
            ctx.fillText(numberWithCommas(data?.prefix), colDataTable[0], currentRowPoint)

            // Promotion
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data?.promotion), colDataTable[1], currentRowPoint)

            // Service charge
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data?.serviceCharge), colDataTable[2], currentRowPoint)

            // Cashback
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data.features?.cashback), colDataTable[3], currentRowPoint)

            // SCB API
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data.features?.scbApi), colDataTable[4], currentRowPoint)

            // Truewallet
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data.features?.truewallet), colDataTable[5], currentRowPoint)

            // Lucky wheel
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data.features?.luckywheel), colDataTable[6], currentRowPoint)

            // API Ambbet
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data.features?.apiAmbbet), colDataTable[7], currentRowPoint)

            // Discount
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'center';
            ctx.fillText(numberWithCommas(data?.discount), colDataTable[8], currentRowPoint)

            // Total
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'right';
            ctx.fillText(numberWithCommas(data?.total), colDataTable[9], currentRowPoint)

            currentRowPoint = currentRowPoint + 25
        })
    }

    const promotionDetail = () => {
        let rowPointPromotion = currentRowPoint + 50

        // Title "Promotion"
        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'start';
        ctx.fillText('Promotions', 90, rowPointPromotion)

        mockupPromotions.forEach((promotion: any) => {
            rowPointPromotion = rowPointPromotion + 30
            ctx.font = '18px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'start';
            ctx.fillText(`${promotion?.code}. ${promotion?.title}`, 90, rowPointPromotion)
        })
    }

    const summaryAmount = () => {
        let rowPointSumAmount = currentRowPoint + 50

        ctx.fillStyle = '#eee'
        ctx.fillRect(780, rowPointSumAmount, 410, 110)
        ctx.fillStyle = '#ffc602'
        ctx.fillRect(780, rowPointSumAmount + 110, 410, 50)

        // Total
        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('รวม', 980, rowPointSumAmount + 30)
        ctx.font = '18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('645,443', 1150, rowPointSumAmount + 30)

        // Discount
        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('ส่วนลด', 980, rowPointSumAmount + 60)
        ctx.font = '18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('0', 1150, rowPointSumAmount + 60)

        // Outstanding
        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('ยอดค้างชำระ', 980, rowPointSumAmount + 90)
        ctx.font = '18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('32', 1150, rowPointSumAmount + 90)

        // Overdue balance
        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('ยอดที่ต้องชำระ', 980, rowPointSumAmount + 140)
        ctx.font = '18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end'
        ctx.fillText('634,475', 1150, rowPointSumAmount + 140)
    }

    const paymentMethod = () => {
        let rowPointPaymentMethod = currentRowPoint + 315

        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'start';
        ctx.fillText('ชำระเงินโดยการฝากเงินสด หรือ หน้าเคาเตอร์ธนาคาร เท่านั้น', 90, rowPointPaymentMethod)

        ctx.font = '18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'start';
        ctx.fillText('ธนาคาร กสิกรไทย (KBANK) / เลขบัญชี 1313768208 / ชื่อ วัฒนาภา กรงทิพย์', 90, rowPointPaymentMethod + 30)

        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'start';
        ctx.fillText('ชำระเงินเรียบร้อยแล้ว รบกวนแจ้งหลักฐานทุกครั้ง', 90, rowPointPaymentMethod + 60)

        ctx.font = 'bold 18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end';
        ctx.fillText('โปรดชำระก่อนวันที่', 1150, rowPointPaymentMethod)

        ctx.font = '18px Arial'
        ctx.fillStyle = '#000'
        ctx.textAlign = 'end';
        ctx.fillText('12/07/2022', 1150, rowPointPaymentMethod + 30)
    }

    const draw = () => {
        loadImages(sources, function callback(images: any) {
            // Header
            ctx.fillStyle = '#000'
            ctx.fillRect(50, 50, 1140, 100)
            ctx.drawImage(images.logo, 90, 75, 300, 57)
            ctx.font = 'bold 30px Arial'
            ctx.fillStyle = '#ffc602'
            ctx.textAlign = 'end'
            ctx.fillText('Invoice', 1150, 110)

            // Customer id
            ctx.font = 'bold 20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'start'
            ctx.fillText('รหัสลูกค้า', 90, 200)
            ctx.font = '20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'start';
            ctx.fillText('MSN-000012', 90, 235)

            // Created date
            ctx.font = 'bold 20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'end';
            ctx.fillText('วันที่ออกใบแจ้งหนี้', 1150, 200)
            ctx.font = '20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'end';
            ctx.fillText('04/07/2022', 1150, 235)

            // Customer name
            ctx.font = 'bold 20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'start'
            ctx.fillText('ออกใบแจ้งหนี้ให้กับ', 90, 290)
            ctx.font = '20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'start';
            ctx.fillText('MSN Support [พี่แทน]', 90, 325)

            // Company
            ctx.font = 'bold 20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'end';
            ctx.fillText('ชำระเงินให้กับ', 1150, 290)
            ctx.font = '20px Arial'
            ctx.fillStyle = '#000'
            ctx.textAlign = 'end';
            ctx.fillText('MSN Bet Co., Ltd', 1150, 325)

            // Table
            dataTable()

            // Line
            ctx.beginPath()
            ctx.moveTo(50, currentRowPoint)
            ctx.lineTo(1190, currentRowPoint)
            ctx.stroke()

            // Promotion
            promotionDetail()

            // Summary amount
            summaryAmount()

            // Line
            ctx.beginPath()
            ctx.moveTo(50, currentRowPoint + 270)
            ctx.lineTo(1190, currentRowPoint + 270)
            ctx.stroke()

            // Payment method
            paymentMethod()
        })
    }

    const exportPdf = async () => {
        const imgData = canvasRef.current?.toDataURL('image/png') || ''
        const setHeightPdf = numPrefix <= 33 ? 297 : (297 + ((numPrefix - 33) * 4.33))
        const pdf = new jsPDF('p', 'mm', [210, setHeightPdf], true)
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        pdf.save('filename.pdf');
        // pdf.output('dataurlnewwindow')
    }

    useEffect(() => {
        init()
    })

    return (
        <>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            <Button type='primary' onClick={exportPdf}>Export Component</Button>
        </>
    )
}
export default Invoice