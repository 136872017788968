import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Form, Checkbox, InputNumber, DatePicker, Row, Col } from 'antd'
import ServiceAPI from '~services/services'
import to from 'await-to-js'
import { ExportData, exportMemberOptions } from '~types/export'

interface ModalProps {
    close: () => void
    isOpen: boolean

    prefixUpdate: any
}

const Export: FC<ModalProps> = ({ isOpen, close, prefixUpdate }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const dateFormat = 'DD/MM/YYYY'
    const insertData = async (values: ExportData) => {
        const [err, res] = await to(ServiceAPI.exportPrefixes(prefixUpdate.prefix, values))
        if (err) {
            Modal.error({
                title: 'เกิดข้อผิดพลาด',
                content: err.message as string,
            });
            setBtnLoading(false)
        } else {
            window.open(
                res.downloadURL,
                '_blank'
            );
            setBtnLoading(false)
            // close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                values.endPoint = process.env.REACT_APP_API_ENDPOINT
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen && prefixUpdate) {
            form.resetFields();
        }
    }, [form, isOpen, prefixUpdate])

    return (
        <Modal
            title={`Export Prefix : ${prefixUpdate.prefix ? (prefixUpdate.prefix).toUpperCase() : ''}`}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .3}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} loading={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Form
                form={form}
                name="exportForm"
                layout="vertical">
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name={"start"}
                            key="start"
                            label={"วันที่เริ่มต้น"}
                            rules={[{ required: true, message: "กรุณากรอกวันที่เริ่มต้น" }]}
                            hasFeedback>
                            <DatePicker placeholder={"วันที่เริ่มต้น"} format={dateFormat} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"end"}
                            key="endDate"
                            label={"วันที่สิ้นสุด"}
                            rules={[{ required: true, message: "กรุณากรอกวันที่สิ้นสุด" }]}
                            hasFeedback>
                            <DatePicker placeholder={"วันที่สิ้นสุด"} format={dateFormat} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="minDeposit"
                    label="ฝากขั้นต่ำ"
                    rules={[{ required: true, message: 'กรุณากรอกฝากขั้นต่ำ!' }]}
                    hasFeedback>
                    <InputNumber placeholder="ฝากขั้นต่ำ" inputMode="numeric" />
                </Form.Item>
                {Object.keys(exportMemberOptions).map((optKey) => (
                    <Form.Item
                        name={['options', optKey]}
                        key={optKey}
                        valuePropName="checked"
                        className="mb-0">
                        <Checkbox>
                            {exportMemberOptions[optKey]}
                        </Checkbox>
                    </Form.Item>
                ))}
            </Form>
        </Modal>
    )
}


export default Export