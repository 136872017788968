import React, { Fragment, useState, useEffect } from 'react'
import { Avatar, Select, Space, Switch, Tooltip, Typography } from 'antd'
// import { ExclamationCircleOutlined } from '@ant-design/icons';
import Button from 'antd-button-color'
import to from 'await-to-js'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import TitleBar from '~components/Elements/TitleBar'
import TableAntd from '~components/Elements/TableAntd'
import Search from '~components/Elements/Search'
import NumberWithCommas from '~utils/NumberWithCommas'

import AddPrefixs from '~components/Prefixs/Add'
import EditPrefixs from '~components/Prefixs/Edit'
import UpdateOwner from '~components/Prefixs/Owner'
import ExportPrefix from '~components/Prefixs/Export'
import { Prefixs as IPrefixs } from '~types/prefixs'
import Fuse from 'fuse.js'

const Prefixs = () => {
    const [dataSource, setDataSource] = useState<any>([])
    const [dataPrefixs, setDataPrefixs] = useState<any>([])
    const [dataFeatures, setDataFeatures] = useState<any>([])
    const [prefixUpdate, setPrefixUpdate] = useState<any>([])
    // const { pagination, setPagination } = useDatePagination()
    // const { dateRange, setDateRange } = useDateRange()

    const [filterText, setFilterText] = useState<string>('')
    const [filterType, setFilterType] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)

    const [isAddOpen, setIsAddOpen] = useState<boolean>(false);
    const toggleModalAdd = () => setIsAddOpen(!isAddOpen)

    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const toggleModalEdit = () => setIsEditOpen(!isEditOpen)

    const [isExportOpen, setIsExportOpen] = useState<boolean>(false);
    const toggleModalExport = () => setIsExportOpen(!isExportOpen)

    const [isOwnerOpen, setIsOwnerOpen] = useState<boolean>(false);
    const toggleModalOwner = () => setIsOwnerOpen(!isOwnerOpen)

    const getPrefixs = async () => {
        setIsLoading(true)
        setDataPrefixs([])
        const [err, res] = await to(ServiceAPI.getPrefixs())
        if (err) {
            toast.error(err?.message as string)
            setIsLoading(false)
        } else {
            setDataPrefixs(Object.values(res).sort((a: any, b: any) => (a.active < b.active ? 1 : -1)))
            // const prefixNotShow = ["UFALIVE"] // ไม่ต้องการแสดง
            // const filteredData = Object.values(res)
            //     .filter((x: any) => x.active !== false && !prefixNotShow.includes(x.prefix))
            //     // .filter((x: any) => !prefixNotShow.includes(x.prefix))
            //     .sort((a: any, b: any) => (a.active < b.active ? 1 : -1));

            // setDataPrefixs(filteredData);
            setIsLoading(false)
        }
    }

    const getFeatures = async () => {
        setDataFeatures([])
        const [err, res] = await to(ServiceAPI.getFeatures())
        if (err) {
            toast.error(err?.message as string)
        } else {
            setDataFeatures(res)
        }
    }

    const handleStatus = async (prefix: string, active: boolean) => {
        setDataSource(
            dataSource.map((x: any) => {
                if (x.prefix === prefix) {
                    x.active = !x.active
                }
                return x
            })
        )
        const [err, res] = await to(ServiceAPI.togglePrefixes(prefix))
        if (err) {
            toast.error(err.message as string)
        } else {
            toast.success(res.message)
        }
        setSwitchLoading(false)
    }

    // const handleDelete = async (prefix: string) => {
    //     Modal.confirm({
    //         title: `คุณต้องการลบ ${prefix.toUpperCase()}?`,
    //         icon: <ExclamationCircleOutlined />,
    //         content: 'เมื่อลบแล้วข้อมูลทั้งหมดจะถูกลบ และไม่สามารถกู้คืนได้',
    //         okText: 'ใช่',
    //         okType: 'danger',
    //         cancelText: 'ไม่',
    //         async onOk() {
    //             const [err, res] = await to(ServiceAPI.deletePrefixes(prefix))
    //             if (err) {
    //                 toast.error(err.message as string)
    //             } else {
    //                 toast.success(res.message)
    //                 getPrefixs()
    //             }
    //         },
    //         onCancel() {

    //         },
    //     });
    // }



    const columns = [
        {
            title: 'Prefix',
            dataIndex: 'prefix',
            render: (text: any, record: any) => (
                <Typography.Text strong>{(record.prefix).toUpperCase()}</Typography.Text>
            )
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            render: (text: any, record: any) => (
                <Space direction="vertical">
                    {record.domain ? <Typography.Link href={`https://${record.domain}`} target="_blank">{record.domain}</Typography.Link> : <Typography.Text>-</Typography.Text>}
                    {record.wallet_domain ? <Space>ทางเข้าเล่น : <Typography.Link href={`https://${record.wallet_domain}`} target="_blank">{record.wallet_domain}</Typography.Link></Space> : <Typography.Text>ทางเข้าเล่น : -</Typography.Text>}
                    {record.bo_domain ? <Space>หลังบ้าน : <Typography.Link href={`https://${record.bo_domain}`} target="_blank">{record.bo_domain}</Typography.Link></Space> : <Typography.Text>หลังบ้าน : -</Typography.Text>}
                </Space>
            )
        },
        {
            title: 'Status',
            dataIndex: 'active',
            align: 'center',
            render: (text: any, record: any) => (
                <Space direction="vertical">
                    <Tooltip title="เปิด/ปิด">
                        <Switch
                            checkedChildren="เปิด"
                            unCheckedChildren="ปิด"
                            checked={record.active}
                            loading={switchLoading}
                            onChange={(e) => {
                                setSwitchLoading(true)
                                handleStatus(record.prefix, record.active)
                            }}
                        />
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Agent',
            dataIndex: 'agent',
            render: (text: any, record: any) => (
                <Typography.Text strong>{(record.config.agent).toUpperCase()}</Typography.Text>
            )
        },
        {
            title: 'Provider',
            dataIndex: 'game',
            render: (text: any, record: any) => (
                <Typography.Text strong>{(record.config.game).toUpperCase()}</Typography.Text>
            )
        },
        {
            title: 'Customer',
            dataIndex: 'owner',
            render: (text: any, record: any) => (
                <div
                    className="text-link"
                    onClick={() => {
                        toggleModalOwner()
                        setPrefixUpdate(record)
                    }}>
                    <Typography.Text strong>
                        <Avatar
                            style={{ backgroundColor: '#0092ff' }}
                            size="small"
                            icon={<i className="fa-duotone fa-user-secret"></i>} /> {record.owner}
                    </Typography.Text>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            align: 'center',
            render: (text: any, record: any) => (
                <Space size={5}>
                    <Button
                        type="warning"
                        size="small"
                        icon={<i className="fa-solid fa-edit me-2"></i>}
                        onClick={() => {
                            toggleModalEdit()
                            setPrefixUpdate(record)
                        }}>
                        แก้ไข
                    </Button>
                    <Button
                        type="info"
                        size="small"
                        icon={<i className="fa-solid fa-file-export me-2"></i>}
                        onClick={() => {
                            toggleModalExport()
                            setPrefixUpdate(record)
                        }}>
                        Export
                    </Button>
                    <Button
                        type="lightdark"
                        size="small"
                        icon={<i className="fa-solid fa-file-invoice me-2"></i>}
                        disabled
                    // onClick={() => { navigate(`edit/${record.username}`) }}
                    >
                        ออกใบแจ้งหนี้
                    </Button>
                    {/* <Button
                        type="danger"
                        size="small"
                        icon={<i className="fa-solid fa-trash me-2"></i>}
                        onClick={() => { handleDelete(record.prefix) }}>
                        ลบ
                    </Button> */}
                </Space >
            )
        }
    ]

    useEffect(() => {
        getPrefixs()
        getFeatures()
        return () => {
            setDataPrefixs([])
            setDataFeatures([])
            setDataSource([])
        }
    }, [])

    const handleSearch = () => {
        if (Object.keys(dataPrefixs).length > 0) {
            let fuseSearch = new Fuse<IPrefixs>(dataPrefixs, {
                keys: ['prefix', 'owner', 'domain', 'config.game', 'config.agent'],
            })
            if (filterType !== "") {
                fuseSearch = new Fuse<IPrefixs>(dataPrefixs, {
                    useExtendedSearch: true,
                    keys: [filterType as string],
                })
            }
            if (filterText !== '') {
                if (filterType !== "") {
                    setDataSource(fuseSearch.search(`^${filterText}`).map((x) => x.item))
                } else {
                    setDataSource(fuseSearch.search(filterText).map((x) => x.item))
                }
            } else {
                setDataSource(dataPrefixs)
            }
        } else {
            setDataSource(dataPrefixs)
        }
    }

    useEffect(() => {
        handleSearch()
    }, [filterText, filterType, dataPrefixs]) // eslint-disable-line


    return (
        <>
            <TitleBar title="เว็บไซต์ทั้งหมด" subTitle={`${NumberWithCommas(dataSource?.length || 0)} รายการ`} />
            <div className="d-flex justify-content-start mb-4">
                <Space>
                    <Select
                        placeholder="--- เลือกการค้นหา ---"
                        showSearch
                        optionFilterProp="children"
                        onSelect={(e: string) => setFilterType(e)}
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Select.Option value="">ทั้งหมด</Select.Option>
                        <Select.Option value="prefix">Prefix</Select.Option>
                        <Select.Option value="domain">Domain</Select.Option>
                        <Select.Option value="config.agent">Agent</Select.Option>
                        <Select.Option value="owner">Customer</Select.Option>
                        <Select.Option value="config.game">Provider</Select.Option>
                    </Select>
                    <Search onChangeSearch={setFilterText} filterText={filterText} />
                </Space>
            </div>
            <div className="row mb-md-3 align-items-md-center">
                <div className="mb-3 mb-md-0">
                    <Button
                        type="primary"
                        className="d-block ms-auto"
                        icon={<i className="fa-solid fa-plus me-3"></i>}
                        onClick={toggleModalAdd}>
                        เพิ่ม Prefix
                    </Button>
                </div>
            </div>
            <div className="box-white ant-box-table">
                <TableAntd
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                />
            </div>
            {isAddOpen && (
                <AddPrefixs
                    isOpen={isAddOpen}
                    close={() => toggleModalAdd()}
                    onloadData={getPrefixs}
                    features={dataFeatures}
                />
            )}
            {isEditOpen && (
                <EditPrefixs
                    isOpen={isEditOpen}
                    close={() => toggleModalEdit()}
                    onloadData={getPrefixs}
                    prefix={prefixUpdate}
                    features={dataFeatures}
                />
            )}
            {isOwnerOpen && (
                <UpdateOwner
                    isOpen={isOwnerOpen}
                    close={() => toggleModalOwner()}
                    onloadData={getPrefixs}
                    prefixUpdate={prefixUpdate}
                />
            )}
            {isExportOpen && (
                <ExportPrefix
                    isOpen={isExportOpen}
                    close={() => toggleModalExport()}
                    prefixUpdate={prefixUpdate}
                />
            )}
        </>
    );
};

export default Prefixs