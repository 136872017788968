import { Col, Form, Input } from 'antd';
import React, { FC } from 'react'
import { PrefixGameMetaTypes } from '~types/prefixs'

interface GameMetaGroupProps {
    gameMeta?: Record<PrefixGameMetaTypes, string>
}
export const SuperlotGroup: FC<GameMetaGroupProps> = ({ gameMeta }) => {
    return (
        <>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "secret"]}
                    label="Superlot Token"
                    rules={[{ required: true, message: 'กรุณากรอก Superlot Token!' }]}
                    hasFeedback>
                    <Input placeholder="Superlot Token" inputMode="text" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "registerSettings"]}
                    label="Superlot Member Copy Setting"
                    rules={[{ required: true, message: 'กรุณากรอก Superlot Member Copy Setting!' }]}
                    hasFeedback>
                    <Input placeholder="Superlot Member Copy Setting" inputMode="text" />
                </Form.Item>
            </Col>
        </>
    )
}
