import React, { FC, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { useNavigate } from "react-router-dom"
// import to from 'await-to-js'
// import { toast } from 'react-toastify'
// import ServiceAPI from '~services/services'

interface EmergencyProps {
    username: string
}
const Emergency: FC<EmergencyProps> = ({ username }) => {
    let navigate = useNavigate();
    const [switchLoading, setSwitchLoading] = useState<boolean>(false)
    const logoutEmergency = async (username: string) => {
        setSwitchLoading(true)
        // if (username.toUpperCase() !== "MSN") {
        //     const [err, res] = await to(ServiceAPI.setUsersRestrict(username, { lock: true }))
        //     if (err) {
        //         toast.error(err?.message as string)
        //         setSwitchLoading(false)
        //     } else {
        //         toast.success(res.message)
        //         setSwitchLoading(false)
        //     }
        // }
        setTimeout(() => {
            localStorage.clear()
            navigate(`/logout?redirect=true`)
        }, 500);
    }
    return (
        <Tooltip title="">
            <Button type="primary" danger className="text-white" onClick={() => logoutEmergency(username)} loading={switchLoading}>
                <i className="fa-solid fa-light-emergency-on me-2"></i>
                Emergency
            </Button>
        </Tooltip>
    );
}

export default Emergency