import { IRoute } from '~types/IRoute'
import Dashboard from '~views/Dashboard'
import Prefixs from '~views/Prefixs'
import PrefixsAdmin from '~views/PrefixsAdmin'
import Invoice from '~views/Invoice'
import Rewards from '~views/Rewards'
import GclubReset from '~views/GclubReset'
import Cloudflare from '~views/Cloudflare'
import Commission from '~views/Commission'

export const menuItems: Array<IRoute> = [
    {
        key: "dashboard",
        title: "Dashboard",
        path: "/dashboard",
        content: Dashboard,
        hide: false,
        permission_key: ''
    },
    {
        key: "prefixs",
        title: "Prefixs",
        path: "/prefixs",
        content: Prefixs,
        hide: false,
        permission_key: ''
    },
    {
        key: "prefixs_admin",
        title: "Prefixs Admin",
        path: "/prefixs-admin",
        content: PrefixsAdmin,
        hide: false,
        permission_key: ''
    },
    {
        key: "invoice",
        title: "Invoice",
        path: "/invoice",
        content: Invoice,
        hide: false,
        permission_key: ''
    },
    {
        key: "rewards",
        title: "Rewards",
        path: "/rewards",
        content: Rewards,
        hide: false,
        permission_key: ''
    },
    {
        key: "gclub",
        title: "GclubReset",
        path: "/gclub-reset-password",
        content: GclubReset,
        hide: false,
        permission_key: ''
    },
    {
        key: "cloudflare",
        title: "Cloudflare",
        path: "/cloudflare",
        content: Cloudflare,
        hide: false,
        permission_key: ''
    },
    {
        key: "commission",
        title: "Commission",
        path: "/commission",
        content: Commission,
        hide: false,
        permission_key: ''
    },
]