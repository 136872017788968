import { Col, Form, Input } from 'antd';
import React, { FC } from 'react'
import { PrefixGameMetaTypes } from '~types/prefixs'

interface GameMetaGroupProps {
    gameMeta?: Record<PrefixGameMetaTypes, string>
}
export const AMBKing: FC<GameMetaGroupProps> = ({ gameMeta }) => {
    return (
        <>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "key"]}
                    label="AMB King Key"
                    rules={[{ required: true, message: 'กรุณากรอก AMB King Key!' }]}
                    hasFeedback>
                    <Input placeholder="AMB King Key" inputMode="text" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "prefix"]}
                    label="AMB King Prefix"
                    rules={[{ required: true, message: 'กรุณากรอก AMB King Prefix!' }]}
                    hasFeedback>
                    <Input placeholder="AMB King Prefix" inputMode="text" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "web"]}
                    label="AMB King Web"
                    rules={[{ required: true, message: 'กรุณากรอก AMB King Web!' }]}
                    hasFeedback>
                    <Input placeholder="AMB King Web" inputMode="text" />
                </Form.Item>
            </Col>
        </>
    )
}
