import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import ThemeProvider, { ThemeContext } from "~contexts/ThemeContext";
import ScreenProvider, { ScreenContext } from "~contexts/ScreenContext";
import Sidebar from '~components/Elements/Sidebar'
import HeaderMobile from '~components/Elements/HeaderMobile'
import AuthInitContainer from '~components/Container/AuthInitContainer'
import { menuItems } from '~utils/RouteConfig'
import ScrollToTop from '~utils/ScrollToTop'

import { Layout } from 'antd';
const { Header, Content, Sider } = Layout;

const AppLayoutChild = () => {
    const [toggleNavbar, setToggleNavbar] = useState(false)
    const { theme } = useContext(ThemeContext)
    const { screen } = useContext(ScreenContext)
    const pathName = useLocation().pathname

    document.body.setAttribute('data-is-dark', (theme === 'dark') ? "true" : "false")
    document.body.setAttribute('data-is-fullscreen', (screen === 'full') ? "true" : "false")

    // NotifyWebSocket()

    useEffect(() => {
        if (toggleNavbar) {
            document.body.classList.add('disabled-scrolling');
        } else {
            document.body.classList.remove('disabled-scrolling');
        }
    }, [toggleNavbar])

    useEffect(() => {
        setToggleNavbar(false)
    }, [pathName])


    return (
        <AuthInitContainer>
            <ScrollToTop />
            <div className="main-container">
                <Layout className="layout-container">
                    <Sider
                        width={250}
                        trigger={null}
                        className={`sidebar-container ${toggleNavbar ? 'open-menu' : ''}`}
                    >
                        <Sidebar />
                    </Sider>
                    <Layout>
                        <Header className="p-0 d-lg-none" style={{ position: 'sticky', top: 0, zIndex: 999 }}>
                            <HeaderMobile
                                toggleNavbar={toggleNavbar}
                                setToggleNavbar={setToggleNavbar}
                            />
                        </Header>
                        <Content>
                            <div style={{ padding: '12px 16px' }}>
                                <Routes>
                                    {menuItems.map((menuItem: any) => (
                                        <Route
                                            element={<menuItem.content />}
                                            key={menuItem.key}
                                            path={menuItem.path}
                                        />
                                    ))}
                                    {/* {router_permission} */}
                                    <Route
                                        path="*"
                                        element={<Navigate to={menuItems[0]?.path} replace />}
                                    />
                                </Routes>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        </AuthInitContainer>
    )
}

const AppLayout = () => (
    <ThemeProvider>
        <ScreenProvider>
            <AppLayoutChild />
        </ScreenProvider>
    </ThemeProvider>
)

export default AppLayout
