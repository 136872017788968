// import { Card } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UncontrolledCollapse } from 'reactstrap'
import '~assets/scss/fontawesome.scss'
import '~assets/scss/sidebar.scss'
import listMenuSidebar from '~utils/SidebarConfig'
// import { useSelector } from 'react-redux'
import NamePrefix from './NamePrefix'
import RestartWorker from './RestartWorker'
import RestartAPI from './RestartAPI'
// import FormattedMessage from '~components/Elements/FormattedMessage'
// import NumberWithCommas from '~utils/NumberWithCommas'
// import { ApplicationState } from '~store/index'
// import Emergency from './Emergency'
// import { Card, Divider } from 'antd'
// import DateTimeNow from './DateTimeNow'

const Sidebar = () => {
  const currentLocation = `/${(useLocation().pathname).split("/")[1]}`
  const [menuSidebar, setMenuSidebar] = useState<any>(listMenuSidebar)
  // const { data } = useSelector((state: ApplicationState) => state.auth)
  // const username = data.username
  // const balance = data.user?.balance
  // const permissions = data.permissions
  // const currency = data.user?.currency ? (data.user?.currency).toUpperCase() : "THB"

  const MenuSideBar = Object.values(menuSidebar).map((item: any) => {
    if (typeof item.submenu === 'undefined') {
      return (
        <li className="nav-item" key={item.id}>
          <Link
            to={item.path}
            key={`link-${item.id}`}
            className={`nav-link link-dark ${(currentLocation === item.path && 'active')}`}>
            <i className={item.icon}></i><span>{item.title}</span>
          </Link>
        </li>
      )
    } else { // if (typeof item.submenu === 'object')
      return (
        <li className="nav-item" key={item.id}>
          <Link
            to={item.path}
            id={`menuId-${item.id}`}
            key={`link-${item.id}`}
            className={`nav-link link-dark ${Object.values(item.submenu).find((item_s: any) => item_s.path === currentLocation) ? 'active' : ''}`}>
            <i className={item.icon}></i>
            <span>{item.title}</span>
            <i className="far fa-chevron-down"></i>
          </Link>
          <UncontrolledCollapse
            className="nav-sub-item"
            key={`nav-sub-link-${item.id}`}
            toggler={`#menuId-${item.id}`}
            defaultOpen={Object.values(item.submenu).find((item_s: any) => item_s.path === currentLocation) ? true : false}>
            {Object.values(item.submenu).map((subItem: any) => {
              return (
                <Link
                  to={subItem.path}
                  key={`link-${subItem.id}`}
                  className={`nav-sub-link ${(currentLocation === subItem.path ? 'active' : '')}`}>
                  <span>{subItem.title}</span>
                </Link>
              )
            })}
          </UncontrolledCollapse>
        </li>
      )
    }
  })

  useEffect(() => {
    setMenuSidebar(listMenuSidebar)
  }, [])


  return (
    <div className="sidebar">
      <div className="d-none d-lg-block">
        <NamePrefix className="mb-5" />
      </div>
      <div className="sidebar-menu">
        <ul className="nav nav-pills flex-column mb-auto mt-2 mt-lg-4">
          {MenuSideBar}
        </ul>
      </div>
      <div className="nav-pills sidebar-footer">
        <Link
          to="/logout"
          key="logout"
          className="nav-link link-dark pb-0">
          <i className="fas fa-sign-out"></i>
          <span>ออกจากระบบ</span>
        </Link>
      </div>
      <div className="mt-auto d-lg-none">
        {/* <Card size="small" className="mb-3">
          <div className="text-center">
            Balance : {NumberWithCommas(balance, 2)} {currency}
          </div>
          <Divider className="my-2" />
          <DateTimeNow />
        </Card> */}
        <div className="d-grid" style={{ marginBottom: "4px" }}>
          <RestartAPI />
        </div>
        <div className="d-grid">
          <RestartWorker />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
