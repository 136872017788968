import useSWR from 'swr'
import { PaginationParams } from '~types/pagination';
import ServiceAPI from '~services/services'
// import serviceAPI from '@/services/serviceAPI'
// import { DateRangeType } from '~types/IDateRange'
// import {
//     IUser
// } from '@/@types/user'
// import { QueryParams } from '@/@types/queryParams'

export const useFetchCloudflare = (projectName: string = "", params?: PaginationParams) => {
    const { data, mutate, error } = useSWR([`/cloudflare/domains/${projectName}`, params?.limit, params?.page], () => ServiceAPI.getCloudflare(projectName, params));

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchCloudflare
