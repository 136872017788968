import React, { FC } from 'react'
// import { ThemeContext } from "~contexts/ThemeContext"
import MsnSlotLogo from '~assets/img/logo-light.png'
interface loadingProps {
    className?: string
}

const NamePrefix: FC<loadingProps> = ({ className }) => {
    // const namePrefix = "MSNSLOT"
    // const { theme } = useContext(ThemeContext)
    return (
        <a href="/" className={`d-flex align-items-center justify-content-center`}>
            {/* className={'name-prefix ' + className} */}
            {/* <div className="firework">
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
                <div className="explosion"></div>
            </div> */}
            {/* <div className="name-prefix-shape"></div>
            <div className="name-prefix-shape"></div>
            <div className="name-prefix-text">
                {namePrefix.toLocaleUpperCase()}
            </div> */}
            <img src={MsnSlotLogo} className="sidebar-logo align-items-center" alt="logo msn" />
        </a>
    );
}

export default NamePrefix