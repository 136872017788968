import { Col, Form, Input, Select } from 'antd';
import React, { FC } from 'react'
import { PrefixGameMetaTypes } from '~types/prefixs'

interface GameMetaGroupProps {
    gameMeta?: Record<PrefixGameMetaTypes, string>
}
export const AMBSuperAPIGroup: FC<GameMetaGroupProps> = ({ gameMeta }) => {
    return (
        <>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "secret"]}
                    label="Secret"
                    rules={[{ required: true, message: 'กรุณากรอก Secret!' }]}
                    hasFeedback>
                    <Input placeholder="Secret" inputMode="text" />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
                <Form.Item
                    name={["gameMeta", "game"]}
                    label="Product"
                    rules={[{ required: true, message: 'กรุณาเลือกเกม!' }]}
                    hasFeedback>
                    <Select
                        placeholder="--- เลือกเกม ---"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                        <Select.Option value="PGSOFT" key="PGSOFT">PG Soft</Select.Option>
                        <Select.Option value="SLOTXO" key="SLOTXO">SlotXO</Select.Option>
                        <Select.Option value="ASKMEBET" key="ASKMEBET">Askmebet</Select.Option>
                        <Select.Option value="AMBPOKER" key="AMBPOKER">Ambpoker</Select.Option>
                        <Select.Option value="AMBSLOT" key="AMBSLOT">AMB Slot</Select.Option>
                        <Select.Option value="HABANERO" key="HABANERO">Habanero</Select.Option>
                        <Select.Option value="SPADE" key="SPADE">Spade Gaming</Select.Option>
                        <Select.Option value="918KISS" key="918KISS">Kiss918</Select.Option>
                        <Select.Option value="SIMPLEPLAY" key="SIMPLEPLAY">Simple Play</Select.Option>
                        <Select.Option value="ICONIC" key="ICONIC">Iconic Gaming</Select.Option>
                        <Select.Option value="MEGA" key="MEGA">Mega888</Select.Option>
                        <Select.Option value="XIN" key="XIN">Xin Gaming</Select.Option>
                        <Select.Option value="LIVE22" key="LIVE22">Live22</Select.Option>
                        <Select.Option value="GAMATRON" key="GAMATRON">Gamatron</Select.Option>
                        <Select.Option value="ALLWAYSPIN" key="ALLWAYSPIN">Allwayspin</Select.Option>
                        <Select.Option value="FUN" key="FUN">Fun Gaming</Select.Option>
                        <Select.Option value="EURASIAN" key="EURASIAN">Eurasian Gaming</Select.Option>
                        <Select.Option value="JDB" key="JDB">JDB</Select.Option>
                        <Select.Option value="WAZDAN" key="WAZDAN">Wazdan</Select.Option>
                        <Select.Option value="EVOPLAY" key="EVOPLAY">Evoplay</Select.Option>
                        <Select.Option value="ENDORPHINA" key="ENDORPHINA">Endorphina</Select.Option>
                        <Select.Option value="CQ9" key="CQ9">CQ9</Select.Option>
                        <Select.Option value="KAGAME" key="KAGAME">KA Gaming</Select.Option>
                        <Select.Option value="JILI" key="JILI">JILI</Select.Option>
                        <Select.Option value="JOKER" key="JOKER">Joker123</Select.Option>
                        <Select.Option value="MANNA" key="MANNA">Mannaplay</Select.Option>
                        <Select.Option value="TIGERGAME" key="TIGERGAME">Tigergame</Select.Option>
                        <Select.Option value="ROYAL" key="ROYAL">Royal Slot Gaming</Select.Option>
                        <Select.Option value="GIOCO" key="GIOCO">Gioco Plus</Select.Option>
                        <Select.Option value="SEXY" key="SEXY">(Casino) Sexy Gaming</Select.Option>
                        <Select.Option value="PRAGMATIC" key="PRAGMATIC">(Casino) Pragmatic Play</Select.Option>
                        <Select.Option value="BIGGAME" key="BIGGAME">(Casino) Big Gaming</Select.Option>
                        <Select.Option value="DREAM" key="DREAM">(Casino) Dream Gaming</Select.Option>
                        <Select.Option value="BETGAME" key="BETGAME">(Casino) BetgamesTV</Select.Option>
                        <Select.Option value="GCLUB" key="GCLUB">(Casino) Royal GClub</Select.Option>
                        <Select.Option value="EBET" key="EBET">(Casino) Ebet</Select.Option>
                        <Select.Option value="ALLBET" key="ALLBET">(Casino) AllBet</Select.Option>
                        <Select.Option value="AGGAME" key="AGGAME">(Casino) Asia Gaming AG</Select.Option>
                        <Select.Option value="PRETTY" key="PRETTY">(Casino) Pretty Gaming</Select.Option>
                        <Select.Option value="SAGAME" key="SAGAME">(Casino) SA Gaming</Select.Option>
                        <Select.Option value="XTREME" key="XTREME">(Casino) Xtreme Gaming</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
        </>
    )
}
