import React, { FC, useState, useEffect } from 'react'
import { Modal, Button, Typography, Form, Input } from 'antd'
import ServiceAPI from '~services/services'
import to from 'await-to-js'

interface ModalProps {
    close: () => void
    isOpen: boolean

    prefixUpdate: any
    onloadData: () => void
}

const Owner: FC<ModalProps> = ({ isOpen, close, prefixUpdate, onloadData }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)

    const insertData = async (values: any) => {
        const [err, res] = await to(ServiceAPI.updateOwnerPrefixes(prefixUpdate.prefix, values.owner))
        if (err) {
            Modal.error({
                title: 'เกิดข้อผิดพลาด',
                content: err.message as string,
            });
            setBtnLoading(false)
        } else {
            Modal.success({
                title: 'ดำเนินการสำเร็จ',
                content: res.message,
            });
            setBtnLoading(false)
            onloadData()
            close()
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen && prefixUpdate) {
            form.resetFields();
            form.setFieldsValue({ 'owner': prefixUpdate.owner })
        }
    }, [form, isOpen, prefixUpdate])

    return (
        <Modal
            title={`ผู้ใช้งาน`}
            visible={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .4}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>บันทึก</Button>,
                <Button key="close" onClick={close}>ปิด</Button>,
            ]}>
            <Typography.Title level={5}>
                Prefix : {prefixUpdate.prefix}
            </Typography.Title>
            <Form
                form={form}
                name="ownerForm"
                layout="vertical">
                <Form.Item
                    name="owner"
                    label="ผู้ใช้งาน"
                    rules={[
                        { required: true, message: 'กรุณากรอก ผู้ใช้งาน!' },
                    ]}
                    hasFeedback>
                    <Input placeholder="ผู้ใช้งาน" inputMode="text" />
                </Form.Item>
            </Form>
        </Modal>
    )
}


export default Owner